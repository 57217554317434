* {
  transition: all 0.5s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:root {
  --white: white;
  --white-accent: #f8f9fc;
}

[data-bs-theme='dark'],
[data-bs-theme='dark'] * {
  --white: #343a40;
  --white-accent: #2f3337;
}

[data-bs-theme='dark'] :is(.dark-bg-dark, .at-title) {
  background-color: var(--white-accent) !important;
  color: white !important;
}

[data-bs-theme='dark'] :is(table, table *:not(button, i, input, a)) {
  background-color: var(--white) !important;
}

[data-bs-theme='dark'] .btn.btn-outline-dark {
  color: white !important;
  border-color: white !important;
}

[data-bs-theme='dark'] .btn-date-picker :is(h5, i) {
  color: white !important;
}

[data-bs-theme='dark'] .dropdown-item.active {
  background-color: var(--white-accent) !important;
}

.dropdown-item.active {
  background-color: var(--bs-light) !important;
  border: rgba(var(--bs-secondary-rgb), 0.3) 1px solid !important;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

#mobile-only,
.mobile-only {
  display: none !important;
}

.progress {
  position: relative;
}

html,
body {
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow-x: hidden;
  position: relative;
  transition: all 0.1s ease-in-out;
}
#main-content {
  transition: all 0.5s ease-in-out;
}

html.h-100,
body.h-100 {
  overflow-y: hidden;
}

.container {
  flex-direction: column;
}

input[type='checkbox']:hover {
  cursor: pointer;
}

.table-hover-custom tr:hover :is(th, td, td :is(div, span, p)):not(thead *) {
  background-color: var(--white-accent) !important;
}

.chart-container {
  width: 80% !important;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  margin: 0;
}

#main-content {
  background: none;
}

#main-content * {
  z-index: 2;
}

canvas:not(#myChart) {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 1 !important;
  height: 86dvh !important;
  width: 100dvw !important;
}

.chart-container-task {
  width: 80% !important;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  margin: 0;
}

.chart {
  width: 80%;
  border-radius: 20px;
  /* background: var(--white); */
  background: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.chart-task {
  border-radius: 20px;
  /* background: var(--white); */
  background: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#nav-cards-row * {
  width: 100%;
}

.week-nav {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* If there's another .rmdp-range after this one, override the styles */
.rmdp-day:not(.rmdp-range) ~ .rmdp-day.rmdp-range {
  color: #000;
  background-color: initial !important;
}

.rmdp-range-picker-footer.ltr.bottom {
  padding-bottom: 0;
}

.rmdp-range-picker-footer > div > button {
  display: none !important;
}

.btn-outline-info-light {
  border-color: rgba(var(--bs-info-rgb), 0.5) !important;
}

.btn-outline-info-light:hover {
  background-color: rgba(var(--bs-info-rgb), 0.5);
  border-color: rgba(1, 2, 3, 0) !important;
}

.controls [class*='btn'] {
  transition: 0.3s all !important;
}

.offcanvas {
  z-index: 99999999 !important;
}

.hov-sec-med:hover {
  background-color: rgba(var(--bs-secondary-rgb), 0.09) !important;
}

.rmdp-container > div {
  height: 37.6px !important;
}

.btn-date-picker * {
  color: #000 !important;
  transition: all 0.2s !important;
}

.hover-white:hover {
  /* color: var(--white) !important; */
  color: white !important;
}

.collapsing-close {
  height: 0 !important;
  transition: height 0.35s ease !important;
}

.collapse-custom.hide {
  height: 0;
}

.collapse-custom.show {
  height: 97.6px;
}

.collapse-custom {
  transition: all 0.35s ease !important;
  overflow-y: hidden !important;
}

.btn-date-picker:hover * {
  color: var(--bs-light) !important;
}

.rmdp-range-picker-footer *,
.rmdp-week-day {
  text-align: center !important;
}

.rmdp-week-day {
  width: 100% !important;
  height: fit-content !important;
  color: var(--primary);
  font-weight: normal !important;
  cursor: text !important;
}

.rmdp-range-picker-footer .rmdp-cancel {
  display: none;
}

.rmdp-calendar {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.rmdp-top-class.rmdp-border-top.rmdp-border-bottom,
.rmdp-toolbar.bottom.toolbar {
  width: 256px !important;
}

/* .rmdp-wrapper.rmdp-shadow.z-max.shadow-sm.p-2.mb-5.rounded.modal.modal-dialog.modal-content.custom-modal {
  width: 272px !important;
} */

.week-nav > i {
  line-height: 1 !important;
}

.btn-img {
  opacity: 1;
  transition: all 0.5s !important;
}

.btn-img:hover {
  opacity: 0.5;
}

.modal.show {
  display: block !important;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--bs-secondary) var(--bs-secondary) var(--bs-light);
  }
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(
    180deg,
    rgba(var(--bs-secondary-rgb), 0.6),
    rgba(var(--bs-secondary-rgb), 0.8)
  );
  border-radius: 14px;
  border: 4px solid var(--white);
}

*::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    180deg,
    rgba(var(--bs-secondary-rgb), 0.8),
    rgba(var(--bs-secondary-rgb), 1)
  );
  border-radius: 14px;
}

*::-webkit-scrollbar-thumb:active {
  background: var(--bs-secondary);
  border-radius: 14px;
  border: 3.5px solid var(--white);
}

body[data-bs-theme='dark']::-webkit-scrollbar-thumb {
  background-color: var(--bs-secondary);
  border-radius: 14px;
  border: 4px solid #212529;
}

.btn-3,
.btn-4,
.media-hr {
  display: none;
}

.user-cont img {
  display: inline-block !important;
}

.logout:hover {
  background-color: rgba(var(--bs-danger-rgb), 0.2);
}

:is(#dropdown-profile, #dropdown-kids) li {
  width: calc(100% - 32px) !important;
}

#dropdown-kids {
  width: 250px;
}

#dropdown-profile li {
  text-align: center !important;
}

.profile-page {
  align-items: center !important;
  justify-content: center !important;
}

.profile-page > div {
  width: 70% !important;
  margin: auto;
}

.animated-button {
  --border-size: 3px;
  --border-angle: 0turn;
  /* width: 60vmin;
  height: 50vmin; */
  background-image: conic-gradient(
      from var(--border-angle),
      var(--bs-primary),
      var(--bs-info) 50%,
      var(--bs-primary)
    ),
    conic-gradient(
      from var(--border-angle),
      transparent 20%,
      var(--bs-info),
      var(--bs-success)
    );
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@property --border-angle {
  syntax: '<angle>';
  inherits: true;
  initial-value: 0turn;
}

@media screen and (min-width: 1200px) {
  h1 > img {
    width: 3rem !important;
    height: 3rem !important;
    margin-bottom: calc(3rem / 4) !important;
  }
}

.vert-cont {
  position: relative;
}

.vert-target {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.af-bg-white:focus:not(:read-only) {
  /* background-color: var(--white) !important; */
  background-color: white !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.add-kid-green :is(button, div) {
  background-color: transparent !important;
  color: var(--bs-success) !important;
}

.add-kid-green:hover {
  background-color: rgba(var(--bs-success-rgb), 0.2);
}

.not-hover-bg-white:not(:hover) {
  /* background-color: var(--white) !important; */
  background-color: white !important;
}

.not-hover-text-danger:not(:hover) {
  color: var(--bs-danger) !important;
}

.not-hover-bg-transparent:not(:hover) {
  background-color: transparent !important;
}

.rings {
  position: relative;
}

.ring {
  --ring-scale: 0.3;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.3),
    10px 10px 15px rgba(70, 70, 70, 0.15),
    inset -10px -10px 15px rgba(255, 255, 255, 0.3),
    inset 10px 10px 15px rgba(70, 70, 70, 0.15);
  border: calc(13px * var(--ring-scale)) solid #ececec;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.\!bg-primary {
  background-color: var(--bs-primary) !important;
}
.\!bg-primary:hover {
  background-color: var(--bs-primary) !important;
}

.animated-text {
  background-image: linear-gradient(
    -45deg,
    var(--bs-danger),
    var(--bs-warning),
    var(--bs-success),
    var(--bs-info),
    var(--bs-primary)
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  color: transparent;
}

.bg-animated {
  background: linear-gradient(
    -45deg,
    var(--bs-danger),
    var(--bs-warning),
    var(--bs-success),
    var(--bs-info),
    var(--bs-primary)
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.min-h-custom {
  min-height: 410.88px;
}
.min-h-custom div {
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80% !important;
}

.bg-animated-b-g {
  background: linear-gradient(
    -45deg,
    var(--bs-success),
    var(--bs-info),
    var(--bs-primary),
    var(--bs-info),
    var(--bs-primary),
    var(--bs-success)
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  color: white !important;
  &:hover {
    animation-play-state: paused;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#mesh-gradient {
  /* --gradient-color-1: var(--bs-primary-bg-subtle);
  --gradient-color-2: var(--bs-info-bg-subtle);
  --gradient-color-3: var(--bs-success-bg-subtle);
  --gradient-color-4: var(--bs-warning-bg-subtle); */
  --gradient-color-1: var(--bs-primary);
  --gradient-color-2: var(--bs-success);
  --gradient-color-3: var(--bs-warning);
  --gradient-color-4: var(--bs-danger);
}

.animation-delay-sm,
.animation-delay-sm * {
  animation-delay: 1s;
}

/* .react-joyride__overlay {
  height: 100dvh !important;
} */

.animation-delay-md,
.animation-delay-md * {
  animation-delay: 2s;
}

.animation-delay-lg,
.animation-delay-lg * {
  animation-delay: 3s;
}

.flowing-text {
  background: #fff;
  position: relative;
  font: 900 30vmin 'Consolas';
  letter-spacing: 5vmin;
  text-shadow: 2px -1px 0 #000, 4px -2px 0 #0a0a0a, 6px -3px 0 #0f0f0f,
    8px -4px 0 #141414, 10px -5px 0 #1a1a1a, 12px -6px 0 #1f1f1f,
    14px -7px 0 #242424, 16px -8px 0 #292929;
}

.fs-0\.5 {
  font-size: calc(11vw) !important;
}

/* General styling for the pre element to mimic VSCode's dark background */
pre {
  background-color: #1e1e1e; /* Dark background */
  color: #d4d4d4; /* Light grey text */
  padding: 16px;
  border-radius: 4px;
  overflow-x: auto; /* For horizontal scrolling */
  font-family: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 14px;
  max-width: 750px;
  margin: auto;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  display: flex;
  align-items: start;
}

/* Styling for the code element */
code {
  color: #9cdcfe; /* Light blue for general code */
  text-align: left !important;
  margin: 0 !important;
}

code * {
  text-align: left !important;
  margin: 0 !important;
}

/* Specific hljs class styling for syntax highlighting */
.hljs-keyword {
  color: #c586c0; /* Purple for keywords */
}

.hljs-title.function_ {
  color: #dcdcaa;
}

.hljs-params {
  color: #9cdcfe; /* Light blue for parameters, similar to general code */
}

.hljs-tag {
  color: #569cd6; /* Blue for HTML tags */
}

.hljs-name {
  color: #4ec9b0; /* Teal for tag names, similar to function names */
}

.hljs-attribute {
  color: #9cdcfe; /* Light blue for attributes, can adjust if needed */
}

/* Additional styles for other elements as needed */
.hljs-comment,
.hljs-quote {
  color: #6a9955; /* Green for comments */
}

.hljs-variable,
.hljs-template-variable {
  color: #9cdcfe; /* Light blue for variables */
}

.hljs-literal,
.hljs-number,
.hljs-regexp,
.hljs-string,
.hljs-meta .hljs-meta-string {
  color: #ce9178; /* Orange for literals, numbers, regex, strings */
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #4ec9b0; /* Teal for built-in classes */
}

.flowing-text::before {
  background-color: var(--bs-primary);
  background-image: radial-gradient(
      closest-side at 50% 50%,
      var(--bs-warning) 100%,
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
      closest-side at 50% 50%,
      var(--bs-danger) 100%,
      rgba(0, 0, 0, 0)
    );
  background-repeat: repeat-x;
  background-size: 40vmin 40vmin;
  background-position: -100vmin 20vmin, 100vmin -25vmin;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  -webkit-animation: moving 10s linear infinite both;
  animation: moving 10s linear infinite both;
  display: block;
  position: absolute;
  content: '';
}
@-webkit-keyframes moving {
  to {
    background-position: 100vmin 20vmin, -100vmin -25vmin;
  }
}
@keyframes moving {
  to {
    background-position: 100vmin 20vmin, -100vmin -25vmin;
  }
}

#envelope {
  animation: float 2s ease-in-out infinite;
}

section#wave-text {
  display: flex;
  background: #fff;
  height: 50vh !important;
  align-items: center;
  justify-content: center;
}

.wave {
  height: 50vh !important;
}

.wave h2 {
  white-space: nowrap;
  color: #fff;
  font-size: 8vw !important;
  position: absolute;
  transform: translate(-50%, -50%);
}

.wave h2:nth-child(1) {
  /* position: absolute; */
  white-space: nowrap;
  background: linear-gradient(to right, #0dcaf0, #0d6efd);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: white; /* This should be transparent, not white */
  /* transform: translate(-50%, -50%); */
  -webkit-text-stroke: 1px #0d6efd; /* Set this to a visible color if a stroke is desired */
}

.wave h2:nth-child(2) {
  color: #0d6efd;
  animation: wave-text 4s ease-in-out infinite;
  /* position: absolute; */

  font-size: 8em;
  white-space: nowrap;
  background: linear-gradient(to right, #0dcaf0, #0d6efd);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* transform: translate(-50%, -50%); */
}

@keyframes wave-text {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0px);
  }
}

.post-title {
  transition: text-decoration 0.5s;
  text-decoration: underline #fff;
}

.blog-post:hover .post-title {
  text-decoration: underline #000;
}
#landing-content {
  .row.m-0 {
    display: flex; /* Ensure row is a flex container, but Bootstrap's row already has this by default. */
    flex-wrap: wrap; /* Allow columns to wrap if necessary. */
  }

  .col-lg-3 {
    display: flex; /* Make the column a flex container to allow its children (i.e., the card) to stretch. */
    display: flex; /* Make the card a flex container. */
    flex-direction: column; /* Ensure the card's content stacks vertically. */
    justify-content: center;
    align-items: center;
  }

  .card {
    flex: 1; /* This will allow the card to take the full height of its parent. */
    display: flex; /* Make the card a flex container. */
    flex-direction: column; /* Ensure the card's content stacks vertically. */
    justify-content: center;
    align-items: center;
  }

  .card-body {
    flex: 1; /* This will allow the card body to take up any remaining space within the card. */
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 992px) {
    .card {
      width: 100% !important;
    }
  }
}

@media screen and (max-height: 470px) {
  .not-found-btn {
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-size: 1rem;
    --bs-btn-border-radius: var(--bs-border-radius);
  }
}
@media screen and (max-height: 800px) {
  .animated-icon {
    width: 50px !important;
    height: 50px !important;
    margin-bottom: 1rem !important;
  }

  div:has(> .animated-icon) {
    font-size: large !important;
  }

  div:has(.animated-icon) {
    font-size: medium;
  }
}

@media screen and not (max-width: 860px) {
  #not-desktop,
  .not-desktop {
    display: none !important;
  }
}

@media screen and (max-width: 1340px) {
  .row {
    width: 100%;
  }
  #main-content,
  .responsive,
  .profile-page > div {
    width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .w-70 {
    width: 95% !important;
  }

  @media screen and (max-width: 1300px) {
    .progress {
      width: 30% !important;
    }
    @media screen and (max-width: 1050px) {
      .fa-2x {
        display: none !important;
        width: 0 !important;
      }
      .progress {
        display: none !important;
      }
      .chart-container,
      .chart-container-task {
        width: 90% !important;
        height: 85vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px !important;
        margin: 0;
      }
      .media-my {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
      .media-my > * {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      .media-p {
        padding: 0.5rem !important;
      }
      .media-p-t {
        padding-bottom: 0.5rem !important;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
      }
      .m-mr-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
      @media screen and (max-width: 945px) {
        .sub-btn,
        .sub-name {
          width: 50% !important;
        }

        #bio-text,
        #bio-card {
          width: 100dvw !important;
          margin-left: 0 !important;
          border-radius: 0 !important;
          min-height: 0 !important;
        }

        #bio-img {
          border-radius: 5rem !important;
          position: inherit !important;
          display: block !important;
          width: 90dvw !important;
          height: 50dvh !important;
          margin: 5rem;
          margin: auto !important;
        }

        #bio {
          padding-top: 1rem;
          display: block !important;
        }
        @media screen and (max-width: 860px) {
          :is(th, th *):not(.not) {
            display: none !important;
          }
          tr:nth-child(1) {
            margin-top: 10px !important;
          }

          .collapse-custom.show {
            height: 130px;
          }

          .collapse-custom.show > * > * > *:not(:first-child) {
            border-bottom: transparent !important;
          }

          #desktop-only,
          .desktop-only {
            display: none !important;
          }

          .not-mobile {
            display: none !important;
          }

          #mobile-only:not(.d-none),
          .mobile-only:not(.d-none) {
            display: table-row !important;
          }

          #mobile-only.edit button.edit-l {
            border-bottom-right-radius: 0px !important;
            border-top-right-radius: 0px !important;
          }
          #mobile-only.edit button.edit-r {
            border-bottom-left-radius: 0px !important;
            border-top-left-radius: 0px !important;
          }
          #mobile-only.edit input {
            border-radius: 0px !important;
          }

          @media screen and (max-width: 790px) {
            #bio-img {
              border-radius: 5rem !important;
              position: inherit !important;
              display: block !important;
              width: 90dvw !important;
              height: 40dvh !important;
              margin: 5rem;
              margin: auto !important;
            }
            .font-md-responsive {
              font-size: 1.125rem !important;
            }
            #hero.container.w-sm-100 {
              padding: 0.75rem !important;
            }
            #hero * {
              text-align: left !important;
            }
            #hero h1 {
              width: 100% !important;
              font-size: 2.5rem !important;
              /* padding-left: 1.75rem !important;
            padding-right: 1.75rem !important; */
            }
            #hero h3 {
              width: 100% !important;
              font-size: 1.1125rem !important;
            }
            .col-auto.d-block.vert-cont.w-25 {
              width: 100% !important;
              margin-top: 80px;
              margin-left: 10px;
            }
            .col.p-4.d-flex.flex-column.position-static {
              margin-top: 60px;
            }
            .media-p {
              padding: 0.1rem !important;
            }
            .media-p * {
              /* font-size: calc(1rem + 0.3vw); */
            }
            /* .input-group-media .input-group-prepend {
              display: none;
            } */
            #per-week {
              padding: 0.25rem !important;
              padding-right: 0 !important;
            }
            #money-form .input-group-text {
              padding: 0.25rem 0.5rem !important;
            }
            #submit-2 {
              padding: 0.3rem;
            }
            .input-group-media .btn {
              font-size: 0.875rem;
            }
            .input-group-media {
              height: 25%;
            }
            .btn-sm:not(
                .btn-light-info-not-hover,
                .btn-light-danger-not-hover,
                .btn-light-success-not-hover
              ) {
              height: 25% !important;
            }
            @media screen and (max-width: 660px) {
              .profile-img {
                width: 50px !important;
                height: 50px !important;
              }
              .user-cont {
                height: 50px !important;
              }
              .profile-list-group {
                margin-top: 25px !important;
              }
              .at-title {
                display: none;
              }
              #hero {
                width: 100dvw !important;
                padding: 1rem !important;
              }
              #hero > * {
                width: 100% !important;
              }
              .chart-container,
              .chart-container-task {
                width: 95% !important;
                height: 85vh;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px !important;
                margin: 0;
              }
              #percent-done {
                display: none;
              }
              /* [id*='react-joyride-step-0'] .__floater.__floater__open {
                width: 60dvw !important;
              } */
              #max-allowance {
                margin-right: 0 !important;
              }
              .sub-btn {
                width: 60% !important;
              }
              .sub-name {
                width: 40% !important;
              }
              @media screen and (max-width: 605px) {
                @media screen and (max-width: 540px) {
                  .sub-btn,
                  .sub-name {
                    width: 100% !important;
                    border-radius: 0.375rem !important;
                  }
                  .sub-btn {
                    margin-top: 1rem;
                  }
                  .calender-date {
                    /* display: none !important; */
                    font-size: 0.8rem;
                  }
                  @media screen and (max-width: 470px) {
                    .profile-img {
                      width: 60px !important;
                      height: 60px !important;
                      margin-top: -11px !important;
                    }
                    .user-cont {
                      height: fit-content !important;
                      display: inline !important;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                    }
                    .user-cont .display-3 {
                      font-size: 30px;
                      margin-left: 0px !important;
                      display: block !important;
                    }
                    .user-cont img {
                      margin-left: -22px !important;
                    }
                    .profile-page {
                      min-width: 100dvw !important;
                      padding: 0 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
