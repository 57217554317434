.b-l-primary {
  border-left: 0.25rem solid rgb(var(--bs-primary-rgb)) !important;
}

.b-l-success {
  border-left: 0.25rem solid rgb(28, 200, 138) !important;
}

.b-l-warning {
  border-left: 0.25rem solid rgb(246, 194, 62) !important;
}

.b-l-info {
  border-left: 0.25rem solid rgb(var(--bs-info-rgb)) !important;
}

.b-l-danger {
  border-left: 0.25rem solid rgb(231, 74, 59) !important;
}

.b-l-secondary {
  border-left: 0.25rem solid rgb(var(--bs-secondary-rgb)) !important;
}

.b-l-light {
  border-left: 0.25rem solid rgb(248, 249, 252) !important;
}

.b-l-dark {
  border-left: 0.25rem solid rgb(90, 92, 105) !important;
}

.center {
  align-items: center;
  justify-content: center;
}

.hover-primary:hover {
  font-weight: bold;
  color: var(--bs-primary) !important;
  cursor: pointer;
}

.hover-secondary:hover {
  font-weight: bold;
  color: var(--bs-secondary) !important;
  cursor: pointer;
}

.hover-success:hover {
  font-weight: bold;
  color: var(--bs-success) !important;
  cursor: pointer;
}

.hover-danger:hover {
  font-weight: bold;
  color: var(--bs-danger) !important;
  cursor: pointer;
}

.hover-warning:hover {
  font-weight: bold;
  color: var(--bs-warning) !important;
  cursor: pointer;
}

.hover-info:hover {
  font-weight: bold;
  color: var(--bs-info) !important;
  cursor: pointer;
}

.hover-light:hover {
  font-weight: bold;
  color: var(--bs-light) !important;
  cursor: pointer;
}

.hover-dark:hover {
  font-weight: bold;
  color: var(--bs-dark) !important;
  cursor: pointer;
}

.mt-8 {
  margin-top: 3rem !important;
}

.center-self {
  align-self: center;
  justify-self: center;
}

.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.w-70 {
  width: 70%;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--white);
  background-clip: border-box;
  border: none;
  border-radius: 0.35rem;
}

.btn-circle {
  font-size: large;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 50%;
}

.font-sm {
  font-size: 12px !important;
}

.alert {
  position: fixed;
  bottom: 0;
  right: 5%;
  z-index: 1055 !important;
}

.btn-light-gray-not-hover:not(:hover) {
  background-color: rgba(109, 118, 126, 0.361);
}

.btn-light-info-not-hover:not(:hover) {
  background-color: rgba(13, 204, 242, 0.361);
}

.btn-light-success-not-hover:not(:hover) {
  background-color: rgba(25, 134, 83, 0.361);
}

.btn-light-danger-not-hover:not(:hover) {
  background-color: rgba(220, 53, 69, 0.361);
  color: rgba(220, 53, 69) !important;
}

.btn-light-danger-not-hover:hover {
  color: white !important;
}

input.accent:not(:hover, :active, :focus) {
  background-color: #6c757d09;
}

[type='checkbox'].accent:disabled {
  background-color: #6c757d !important;
  border-color: #6c757da3 !important;
}

.rmdp-day.rmdp-range {
  box-shadow: none;
  background-color: rgb(var(--bs-primary-rgb));
}

.rmdp-day.rmdp-range.start {
  border-top-left-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rmdp-day.rmdp-range.end {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rmdp-day.rmdp-range .sd {
  color: var(--white) !important;
}

.rmdp-day.rmdp-today .sd {
  background-color: rgba(var(--bs-info-rgb), 0.5);
  color: #000 !important;
}

.rmdp-day .sd:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.6) !important;
  color: var(--white) !important;
}

.rmdp-day.rmdp-today.rmdp-range span {
  background-color: var(--bs-primary) !important;
  border: 1px dashed var(--bs-info) !important;
  color: var(--white) !important;
}

[class*='rmdp'],
[class*='rmdp'] * {
  transition: all 0.5s !important;
}

.z-max {
  z-index: 100000000000000000000000000000000 !important;
}

.z-max * {
  z-index: 100000000000000000000000000000000 !important;
}

nav,
nav * {
  z-index: 99 !important;
}

.\!bg-primary {
  background-color: var(--bs-primary) !important;
}

/* Change the highlight color */
::selection {
  background-color: var(--bs-primary); /* Replace with your desired color */
  color: var(--white); /* Replace with your desired text color */
}

/* Overlay Styles */
.overlay {
  /* display: none; Hide the overlay by default */
  position: fixed; /* Stay in place */
  z-index: 999999999999 !important; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.4); /* Black background with opacity */
}

body > div:not(#root, [id*='react-joyride']) > div {
  /* display: none; Hide the modal by default */
  position: fixed !important; /* Stay in place */
  z-index: 9999999999999 !important; /* Sit on top of the overlay */
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: fit-content !important;
}

#react-joyride-portal {
  z-index: 9999999999999 !important; /* Sit on top of the overlay */
}

/* html:not(.modal-shown)
  body.modal-hidden:not(.modal-shown)
  > div:not(#root)
  > div,
html.modal-hidden:not(.modal-shown)
  body:not(.modal-shown)
  > div:not(#root)
  > div {
  display: none; Hide the modal by default
  visibility: hidden !important;
  display: none !important;
} */
